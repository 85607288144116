import classes from "./Imformation.module.css";

const Imformation = () => {
  return (
    <div className={classes.imformation}>
      <div className={classes.ship}>
        <div className="col text-center">
          <h5>FREE SHIPPING</h5>
          <p className="">Free shipping worlwide</p>
        </div>
        <div className="col text-center">
          <h5>24 x 7 SERVICE</h5>
          <p>Free shipping worlwide</p>
        </div>
        <div className="col text-center">
          <h5>FESTIVAL OFFER</h5>
          <p>Free shipping worlwide</p>
        </div>
      </div>
      <div className={classes.subscribe}>
        <div>
          <h5>LET'S BE FRIENDS!</h5>
          <p>Nisi nisi tempor consequat laboris nisi</p>
        </div>
        <div className="d-flex">
          <input type="text" placeholder="Enter your email address" />
          <button type="button">Subscribe</button>
        </div>
      </div>
    </div>
  );
};

export default Imformation;
