import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "../store";
import classes from "./FormCheck.module.css";

const FormCheck = () => {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [addressTouched, setAddressTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);
  const nameIsValid = nameInput.trim() !== "";
  const emailIsValid = emailInput.includes("@");
  const addressIsValid = addressInput.length >= 5;
  const phoneIsValid = phoneInput.trim() !== "" && phoneInput > 0;
  const nameValueIsValid = !nameIsValid && nameTouched;
  let emailValueIsValid = !emailIsValid && emailTouched;
  const addressValueIsValid = !addressIsValid && addressTouched;
  const phoneValueIsValid = !phoneIsValid && phoneTouched;

  let formIsValid = false;

  if (nameIsValid && emailIsValid && addressIsValid && phoneIsValid) {
    formIsValid = true;
  }
  // xử lý validate cho tát cả input đăng ký tài khoản
  const inputNameHandler = event => {
    setNameInput(event.target.value);
  };
  const nameInputBlurHandler = event => {
    setNameTouched(true);
  };
  const inputAddressHandler = event => {
    setAddressInput(event.target.value);
  };
  const addressInputBlurHandler = event => {
    setAddressTouched(true);
  };
  const inputPhoneHandler = event => {
    setPhoneInput(event.target.value);
  };
  const phoneInputBlurHandler = event => {
    setPhoneTouched(true);
  };
  const emailInputBlurHandler = event => {
    setEmailTouched(true);
  };
  const inputEmailHandler = event => {
    setEmailInput(event.target.value);
  };

  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const totalPrice =
    cart.length > 0
      ? cart
          .map(item => item.price * (item.value > 0 ? item.value : 1))
          .reduce((x, y) => x + y)
      : 0;
  // tạo 1 object chứa các thông tin user
  const user = {
    name: nameInput,
    email: emailInput,
    address: addressInput,
    phone: phoneInput,
    totalPrice: totalPrice,
    cart: cart,
    userId: sessionStorage.getItem("userId"),
  };
  const navigate = useNavigate();
  // Lấy tất cả các cookies hiện có
  const cookies = document.cookie;

  // Chuyển đổi chuỗi cookies thành một đối tượng
  const cookiesArray = cookies.split("; ");
  const cookiesObject = cookiesArray.reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {});

  // Lấy giá trị của token từ đối tượng cookies
  const token = cookiesObject.token; // Thay 'token' bằng tên của cookie bạn muốn lấy

  // khi order sẽ kiểm tra người dùng đã đăng nhập chưa mới được order
  const order = async user => {
    try {
      const response = await fetch("https://hoaifood.onrender.com/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();
  const submitHandler = async event => {
    event.preventDefault();

    try {
      const response = await order(user);
      if (response.status === 201) {
        navigate("/shop");
        dispatch(loginAction.resetCart());
        localStorage.setItem("cart", JSON.stringify([]));
      } else {
        const error = await response.json();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className={classes.formCheck} onSubmit={submitHandler}>
      <label htmlFor="">TÊN:</label>
      <input
        type="text"
        placeholder="Enter your full name here!"
        onChange={inputNameHandler}
        onBlur={nameInputBlurHandler}
        value={nameInput}
        className={nameValueIsValid && classes.invalid}
      />
      <label htmlFor="">EMAIL:</label>
      <input
        type="text"
        placeholder="Enter your Email here!"
        onChange={inputEmailHandler}
        onBlur={emailInputBlurHandler}
        value={emailInput}
        className={emailValueIsValid && classes.invalid}
      />
      <label htmlFor="">SỐ ĐIỆN THOẠI:</label>
      <input
        type="text"
        placeholder="Enter your phone number here!"
        onChange={inputPhoneHandler}
        onBlur={phoneInputBlurHandler}
        value={phoneInput}
        className={phoneValueIsValid && classes.invalid}
      />
      <label htmlFor="">ĐỊA CHỈ GIAO HÀNG:</label>
      <input
        type="text"
        placeholder="Enter your address here!"
        onChange={inputAddressHandler}
        onBlur={addressInputBlurHandler}
        value={addressInput}
        className={addressValueIsValid && classes.invalid}
      />
      {totalPrice > 0 && (
        <button type="submit" disabled={!formIsValid}>
          Đặt Hàng
        </button>
      )}
    </form>
  );
};

export default FormCheck;
