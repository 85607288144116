import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OrderItem from "./checkout/OrderItem";
import { Helmet } from "react-helmet";
import "./AOrder.css";

const AOrder = () => {
  const [data, setData] = useState({
    userId: "",
    name: "",
    phone: "",
    address: "",
    totalPrice: "",
  });
  const params = useParams();

  async function fetchApi() {
    try {
      const response = await fetch(
        `https://hoaifood.onrender.com/order/${params.orderId}`
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      setData(data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <div className="container-order">
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div>
        <h4>Thông Tin Đơn</h4>
        <p>ID User: {data.userId}</p>
        <p>Tên: {data.name}</p>
        <p>Số Điện Thoại: {data.phone}</p>
        <p>Địa Chỉ: {data.address}</p>
        <p>Total: {Number(data.totalPrice).toLocaleString()} VND</p>
      </div>
      <table>
        <thead>
          <tr className="grid5">
            <th>Ảnh</th>
            <th>Tên</th>
            <th>Giá</th>
            <th>Số Lượng</th>
          </tr>
        </thead>
        <tbody>
          <OrderItem item={data.cart} />
        </tbody>
      </table>
    </div>
  );
};

export default AOrder;
