import "./OrderItem.css";

const OrderItem = props => {
  return (
    <div>
      {props.item &&
        props.item.map(item => (
          <tr className="grid5">
            <td>
              <img src={item.img1} alt="" />
            </td>
            <td>{item.name}</td>
            <td>{Number(item.price).toLocaleString()} VND</td>
            <td>{item.value}</td>
          </tr>
        ))}
    </div>
  );
};
export default OrderItem;
