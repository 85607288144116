import FormCheck from "./checkout/FormCheck";
import Order from "./checkout/Order";
import { Helmet } from "react-helmet";
import classes from "./CheckoutPage.module.css";

const Checkout = () => {
  return (
    <div className={classes.checkout}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="row bg-light p-5 d-flex justify-content-between">
        <h2>CHECKOUT</h2>
        <div className={classes.check}>
          <p> HOME / </p>
          <p>CART / </p>
          <p className={classes.checkLight}> CHECKOUT</p>
        </div>
      </div>
      <h4 className="my-5">THÔNG TIN ĐẶT HÀNG</h4>
      <div className={classes.mainCheckout}>
        <FormCheck />
        <Order />
      </div>
    </div>
  );
};
export default Checkout;
