import {
  createSlice,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import localStorageMiddleware from "./storage";

const initialPopupState = {
  isOpen: false,
  productData: null,
  data: 0,
};
// slice cho popup
const popupSlice = createSlice({
  name: "popup",
  initialState: initialPopupState,
  reducers: {
    showPopup(state, action) {
      state.isOpen = true;
      state.productData = action.payload;
    },
    hidePopup(state) {
      state.isOpen = false;
      state.productData = null;
    },
  },
});
const initialLoginState = {
  isLogin: false,
  user: { email: "", cart: [] },
};
// slice cho login và cart
const loginSlice = createSlice({
  name: "login",
  initialState: initialLoginState,
  reducers: {
    login(state, action) {
      state.isLogin = true;
      state.user = action.payload;
    },
    logout(state) {
      state.isLogin = false;
      state.user = null;
    },
    addCart(state, action) {
      state.user.cart.push(action.payload);
    },
    updateCart(state, action) {
      const { productId, newQuantity } = action.payload;

      // Tìm kiếm sản phẩm trong giỏ hàng
      const updatedCart = state.user.cart.map(product => {
        if (product.id === productId) {
          // Cập nhật số lượng mới cho sản phẩm
          return { ...product, value: newQuantity };
        }
        return product;
      });

      state.user.cart = updatedCart;
    },
    deleCart(state, action) {
      const index = action.payload; // Chỉ mục của sản phẩm cần xóa trong giỏ hàng

      // Sử dụng splice() để xóa phần tử tại chỉ mục index
      state.user.cart.splice(index, 1);
      console.log(action.payload);
    },
    resetCart(state) {
      state.user.cart = [];
    },
  },
});

// Tạo một danh sách các middleware mặc định và thêm localStorageMiddleware
const middleware = [...getDefaultMiddleware(), localStorageMiddleware];

const store = configureStore({
  reducer: { popup: popupSlice.reducer, login: loginSlice.reducer },
  middleware, // Sử dụng danh sách middleware đã cấu hình
});
export const popupAction = popupSlice.actions;
export const loginAction = loginSlice.actions;
export default store;
