import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/free-solid-svg-icons";
import classes from "./CartTotal.module.css";

const CartTotal = () => {
  // dấy dữ liệu về tổng tiền các vật phẩm mua

  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  // lấy danh sách vật phẩm rồi tính tổng tiền nếu đang login
  const cartData =
    cart.length > 0
      ? cart
          .map(item => item.price * (item.value > 0 ? item.value : 1))
          .reduce((x, y) => x + y)
      : 0;

  return (
    <div className={classes.totalCart}>
      <h5>CART TOTAL</h5>
      <div className={classes.subTotal}>
        <h6>SUBTOTAL</h6>
        <p>{Number(cartData).toLocaleString()} VND</p>
      </div>
      <div className={classes.total}>
        <h6>TOTAL</h6>
        <p>{Number(cartData).toLocaleString()} VND</p>
      </div>
      <div className={classes.coupon}>
        <input type="text" placeholder="Enter your coupon" />
        <button>
          <FontAwesomeIcon icon={faGift} />
          Apply coupon
        </button>
      </div>
    </div>
  );
};

export default CartTotal;
