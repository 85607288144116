import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap"; // Import Carousel từ react-bootstrap
import classes from "./Banner.module.css";
import banner from "../../image/banner1.png";
import banner1 from "../../image/banner2.png";
import banner2 from "../../image/banner3.png";

const Banner = () => {
  return (
    <div className={classes.banner}>
      <div className={classes.carou}>
        <Carousel>
          <Carousel.Item>
            <img src={banner} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={banner1} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img src={banner2} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default Banner;
