import React from "react";

import classes from "./Product.module.css";

const Product = props => {
  const formattedPrice = Number(props.price).toLocaleString();

  return (
    <div className={classes.product} onClick={props.sendItem}>
      <img src={props.img} alt={props.name} />
      <h6 className="text-center">{props.name}</h6>
      <p className="text-center">{formattedPrice} đ</p>
    </div>
  );
};

export default Product;
