import Product from "../shopComponent/Product";
import classes from "./RelatedProduct.module.css";

const Related = props => {
  return (
    <>
      <h4>SẢN PHẨM LIÊN QUAN</h4>
      <div className={classes.related}>
        {props.item.map(item => (
          <Product
            key={item._id}
            id={item._id}
            short_desc={item.short_desc}
            long_desc={item.long_desc}
            category={item.category}
            name={item.name}
            price={item.price}
            img={item.img1}
          />
        ))}
      </div>
    </>
  );
};

export default Related;
