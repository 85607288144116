import Banner from './homeComponent/Banner';
import Categories from './homeComponent/Categories';
import ProductList from './homeComponent/ProductList';
import Imformation from './homeComponent/Imformation';
import { Helmet } from 'react-helmet';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
        <title>Đặc sản Bình Định</title>
        <meta
          name="description"
          content="Những món đặc sản đặc trưng của miền đất võ Bình Định"
        />
      </Helmet>
      <Banner />
      <Categories />
      <ProductList />
      <Imformation />
    </>
  );
};
export default HomePage;
