import { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Related from './detailComponent/RelatedProduct';
import Detail from './detailComponent/DetailProduct';
import classes from './DetailPage.module.css';

const DetailPage = () => {
  // lấy dữ liệu productId và category từ useParams
  const param = useParams().productId.split('-');
  const [dataProduct, setDataProduct] = useState([]);
  const [relate, setRelate] = useState([]);
  const fetchProductApi = useCallback(async () => {
    try {
      const response = await fetch(
        `https://hoaifood.onrender.com/admin/products`,
      );

      if (!response.ok) {
        throw new Error('Đã xảy ra lỗi!');
      }

      const data = await response.json();
      // lọc ra sản phẩm được chọn và related của nó
      setDataProduct(data.filter(item => item._id === param[0])[0]);
      setRelate(
        data.filter(
          item => item.category === param[1] && item._id !== param[0],
        ),
      );
    } catch (err) {
      console.log(err);
    }
  }, [param]);

  useEffect(() => {
    fetchProductApi();
  }, [fetchProductApi]);

  return (
    <div className={classes.detailContainer}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
        <title>Chả Ram Tôm Đất</title>
        <meta
          name="description"
          content="Chả ram tôm đất Bình Định là món chả ram nổi tiếng nhất với sự bắt mắt và vị ngon"
        />
      </Helmet>
      <Detail item={dataProduct} />
      <div>
        <p type="button" className="btn btn-dark">
          MÔ TẢ
        </p>
        <h5>MÔ TẢ SẢN PHẨM</h5>
        <p>{dataProduct.long_desc}</p>
      </div>
      <Related item={relate} />
    </div>
  );
};
export default DetailPage;
