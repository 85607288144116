import { useState } from "react";
import classes from "./Categories.module.css";

const Categories = props => {
  const [category, setCategory] = useState("all");
  // các hàm xử lý khi lựa chọn các nút lọc trên categories
  const clickAllHandler = () => {
    props.onCategoryChange("all");
    setCategory("all");
  };
  const clickMam = () => {
    props.onCategoryChange("mam");
    setCategory("mam");
  };
  const clickChaHandler = () => {
    props.onCategoryChange("cha");
    setCategory("cha");
  };
  const clickBanhHandler = () => {
    props.onCategoryChange("banh");
    setCategory("banh");
  };

  return (
    <div className={classes.categories}>
      <h4 className="mb-3">CATEGORIES</h4>
      <p className="branch bg-dark text-white py-2 px-2">All</p>
      <button
        onClick={clickAllHandler}
        className={category === "all" ? "text-warning" : ""}
      >
        Tất Cả
      </button>
      <p className={classes.type}>Nước Mắm</p>
      <button
        onClick={clickMam}
        className={category === "mam" ? "text-warning" : ""}
      >
        Mắm
      </button>

      <p className={classes.type}>Nem & Chả</p>
      <button
        onClick={clickChaHandler}
        className={category === "cha" ? "text-warning" : ""}
      >
        Nem Chả
      </button>

      <p className={classes.type}>Khác</p>
      <button
        onClick={clickBanhHandler}
        className={category === "banh" ? "text-warning" : ""}
      >
        Các Loại Bánh
      </button>
    </div>
  );
};

export default Categories;
