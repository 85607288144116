import { useSelector } from "react-redux";

import classes from "./Order.module.css";

const Order = () => {
  // lấy danh sách vật phẩm mua và liệt kê
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const userData = useSelector(state => state.login);

  // tính tổng tiền
  const cartData =
    cart.length > 0
      ? cart.map(item => item.price * item.value).reduce((x, y) => x + y)
      : 0;

  return (
    <div className={classes.totalCart}>
      <h4>YOUR ORDER</h4>
      {cart.length &&
        cart.map(item => (
          <div className={classes.subTotal} key={item.id}>
            <p className="fw-bold">{item.name}</p>
            <p>
              {" "}
              {Number(item.price).toLocaleString()}VND X {item.value}
            </p>
          </div>
        ))}

      <div className={classes.total}>
        <h6>TỔNG TIỀN</h6>
        <p>{Number(cartData).toLocaleString()} VND</p>
      </div>
    </div>
  );
};

export default Order;
