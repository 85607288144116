import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./component/pages/HomePage";
import DetailPage from "./component/pages/DetailPage";
import CartPage from "./component/pages/CartPage";
import Checkout from "./component/pages/CheckoutPage";
import ShopPage from "./component/pages/ShopPage";
import LoginPage from "./component/pages/LoginPage";
import RegisterPage from "./component/pages/RegisterPage";
import Layout from "./component/layout/Layout";
import HistoryPage from "./component/pages/History";
import AOrder from "./component/pages/AOrder";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="detail/:productId" element={<DetailPage />} />
          <Route path="cart" element={<CartPage />} />
          <Route path="check-out" element={<Checkout />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="orders" element={<HistoryPage />} />
          <Route path="order/:orderId" element={<AOrder />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
