import { useState, useEffect } from "react";
import HistoryItem from "./HistoryItem";
import classes from "./History.module.css";

const History = () => {
  const userId = sessionStorage.getItem("userId");
  const [data, setData] = useState([]);
  async function fetchApi() {
    try {
      const response = await fetch(
        `https://hoaifood.onrender.com/orders/${userId}`
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      setData(data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    fetchApi();
  }, []);
  return (
    <div className={classes.containerHis}>
      <div className={classes.title}>
        <h6>ID Đơn</h6>

        <h6>Tổng Tiền</h6>
        <h6>Trạng Thái</h6>
        <h6>Chi Tiết</h6>
      </div>
      {data.length > 0 &&
        data.map(item => <HistoryItem item={item} key={item.id} />)}
    </div>
  );
};

export default History;
