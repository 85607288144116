import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import classes from "./LoginPage.module.css";
import banner from "../image/banner4.png";
import { Helmet } from "react-helmet";
const LoginPage = () => {
  const [emailInput, setEmailInput] = useState("");
  const [passInput, setPassInput] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [passTouched, setPassTouched] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const isEmailValid = emailInput.includes("@");
  const isPassValid = passInput.length >= 5;

  const formIsValid = isEmailValid && isPassValid;

  const navigate = useNavigate();

  const login = async (email, password) => {
    try {
      const response = await fetch("https://hoaifood.onrender.com/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const handleBlur = field => {
    if (field === "email") {
      setEmailTouched(true);
    } else if (field === "password") {
      setPassTouched(true);
    }
  };

  const handleInputChange = (field, value) => {
    setErrorMessages([]);
    if (field === "email") {
      setEmailInput(value);
    } else if (field === "password") {
      setPassInput(value);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const response = await login(emailInput, passInput);
      const data = await response.json();

      if (response.status === 200) {
        const token = data.token;
        document.cookie = `token=${token}; max-age=3600; path=/; secure`;
        sessionStorage.setItem("userId", data.userId);
        sessionStorage.setItem("email", data.email);
        sessionStorage.setItem("name", data.name);
        navigate("/");
      } else {
        const errors = data.errors || ["An error occurred while logging in."];
        setErrorMessages(errors);
      }
    } catch (error) {
      console.error(error);
      setErrorMessages(["An error occurred while logging in."]);
    }
  };

  return (
    <div
      className={classes.mainLogin}
      style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className={classes.loginForm}>
        <form onSubmit={handleSubmit}>
          <h4 className="text-center mb-5 text-secondary">SIGN IN</h4>
          {errorMessages.length > 0 && (
            <div className={classes.errorContainer}>
              {errorMessages.map((error, index) => (
                <p key={index} className={classes.textErr}>
                  {error}
                </p>
              ))}
            </div>
          )}
          <input
            type="text"
            placeholder="Email"
            value={emailInput}
            onChange={e => handleInputChange("email", e.target.value)}
            onBlur={() => handleBlur("email")}
            className={emailTouched && !isEmailValid ? classes.invalid : ""}
          />
          <input
            type="password"
            placeholder="Password"
            value={passInput}
            onChange={e => handleInputChange("password", e.target.value)}
            onBlur={() => handleBlur("password")}
            className={passTouched && !isPassValid ? classes.invalid : ""}
          />

          <button disabled={!formIsValid}>SIGN IN</button>
          <p className="mt-5 text-secondary">
            Create an account? <Link to="/register">Sign Up</Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
