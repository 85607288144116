import { NavLink } from 'react-router-dom';

import React from 'react';
import Livechat from './Livechat';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import classes from './NavBar.module.css';
import logo from '../image/logo.png';

const NavBar = () => {
  // dùng useSelector để lấy dữ liệu state của redux
  // const userData = useSelector(state => state.login);
  const name = sessionStorage.getItem('name') || null;
  // useState cho bật tắt livechat
  const [isChat, setIsChat] = useState(false);
  const navigate = useNavigate();
  function showChatHandler() {
    setIsChat(!isChat);
  }
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  // dispatch cho nút logout
  function logoutHandler() {
    // remove token and session
    removeCookie('token');
    sessionStorage.clear();
    localStorage.removeItem('cart');
    navigate('/login');
  }

  return (
    <>
      <nav className={classes.navbar}>
        <ul>
          <div>
            <li className={classes.home}>
              {/* sử dụng NavLink cho các nút của navbar */}
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
                end
              >
                Home
              </NavLink>
            </li>
            <li className={classes.shop}>
              <NavLink
                to="/shop"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Shop
              </NavLink>
            </li>
            <li className={classes.order}>
              <NavLink
                to="/orders"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                Order
              </NavLink>
            </li>
          </div>

          <li>
            <NavLink to="/">
              <img src={logo} alt="" />
            </NavLink>
          </li>
          <div className={classes.icon}>
            <li className={classes.cart}>
              <NavLink
                to="/cart"
                className={({ isActive }) =>
                  isActive ? classes.active : undefined
                }
              >
                <FontAwesomeIcon icon={faShoppingCart} />
                Cart
              </NavLink>
            </li>
            <li className={classes.login}>
              {name ? (
                <p>
                  <FontAwesomeIcon icon={faUser} />
                  {name}
                  <button onClick={logoutHandler}>- Logout</button>
                </p>
              ) : (
                <NavLink
                  to="/login"
                  className={({ isActive }) =>
                    isActive ? classes.active : undefined
                  }
                >
                  <FontAwesomeIcon icon={faUser} />
                  Login
                </NavLink>
              )}
            </li>
          </div>
        </ul>
        {/* nút bật tắt livechat */}
        <div className={classes.livechat}>
          <svg x="0" y="0" width="60px" height="60px" onClick={showChatHandler}>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g>
                <circle fill="#383838" cx="30" cy="30" r="30"></circle>
                <svg x="10" y="10">
                  <g transform="translate(0.000000, -10.000000)" fill="#FFFFFF">
                    <g id="logo" transform="translate(0.000000, 10.000000)">
                      <path
                        d="M20,0 C31.2666,0 40,8.2528 40,19.4 C40,30.5472 31.2666,38.8 
								20,38.8 C17.9763,38.8 16.0348,38.5327 14.2106,38.0311 C13.856,37.9335 13.4789,37.9612 
								13.1424,38.1098 L9.1727,39.8621 C8.1343,40.3205 6.9621,39.5819 6.9273,38.4474 L6.8184,34.8894 
								C6.805,34.4513 6.6078,34.0414 6.2811,33.7492 C2.3896,30.2691 0,25.2307 0,19.4 C0,8.2528 8.7334,0 
								20,0 Z M7.99009,25.07344 C7.42629,25.96794 8.52579,26.97594 9.36809,26.33674 L15.67879,21.54734 
								C16.10569,21.22334 16.69559,21.22164 17.12429,21.54314 L21.79709,25.04774 C23.19919,26.09944 
								25.20039,25.73014 26.13499,24.24744 L32.00999,14.92654 C32.57369,14.03204 31.47419,13.02404 
								30.63189,13.66324 L24.32119,18.45264 C23.89429,18.77664 23.30439,18.77834 22.87569,18.45674 
								L18.20299,14.95224 C16.80079,13.90064 14.79959,14.26984 13.86509,15.75264 L7.99009,25.07344 Z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </g>
            </g>
          </svg>
        </div>
        {isChat && <Livechat />}
      </nav>
    </>
  );
};

export default NavBar;
