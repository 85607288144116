import History from "./checkout/History";
import { Helmet } from "react-helmet";
const HistoryPage = () => {
  return (
    <div>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <History />
    </div>
  );
};

export default HistoryPage;
