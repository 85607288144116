import React, { useEffect, useState } from "react";
import admin from "../image/admin.png";
import classes from "./Livechat.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faFaceSmile,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import io from "socket.io-client";
const socket = io("https://hoaifood.onrender.com", {
  transports: ["websocket"],
});

const Livechat = () => {
  const [message, setMessage] = useState([]);
  const [textMessage, setTextMessage] = useState("");
  let userId = sessionStorage.getItem("userId");
  let random = Math.random();
  userId = userId ? userId : random;
  sessionStorage.setItem("userId", userId);
  const onChangeText = e => {
    setTextMessage(e.target.value);
  };
  useEffect(() => {
    // Hàm này dùng để load dữ liệu message khi component được render
    const fetchData = async () => {
      const result = await fetch(
        `https://hoaifood.onrender.com/chatrooms/getMessageByUserId/${userId}`
      );
      const data = await result.json();
      if (data.length > 0) {
        setMessage(data[0].content);
      }
    };
    fetchData();
  }, [userId]);
  useEffect(() => {
    // Nhận dữ liệu từ server gửi lên thông qua socket với key receive_message
    socket.on("receive_message", data => {
      // Update the messages state with the new message
      setMessage(prevMessages => [...prevMessages, data]);
    });
    // Cleanup the socket listener when the component unmounts
    return () => {
      socket.off("receive_message");
    };
  }, []);

  const sendMessage = async () => {
    if (textMessage.trim() === "") {
      // Nếu tin nhắn rỗng, không làm gì cả
      return;
    }
    if (message.length === 0) {
      // Nếu không có cuộc trò chuyện, tạo một cuộc trò chuyện mới
      const data = {
        content: [{ message: textMessage, is_admin: false }],
        userId: userId,
      };
      await createRoomChat(data);
    } else {
      // Nếu cuộc trò chuyện đã tồn tại, gửi tin nhắn vào cuộc trò chuyện đó
      const newMessage = { message: textMessage, is_admin: false };
      const updatedMessages = [...message, newMessage];

      const data = {
        content: updatedMessages,
        userId: userId,
      };

      await addMessage(data);

      // Gửi tin nhắn mới qua WebSocket
      socket.emit("send_message", newMessage);
    }
    setTextMessage("");
  };
  const createRoomChat = async data => {
    const result = await fetch(
      "https://hoaifood.onrender.com/chatrooms/createNewRoom",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = await result.json();
    // Lấy dữ liệu tin nhắn từ phản hồi và cập nhật state message
    if (responseData.content) {
      setMessage(responseData.content);
    }
  };
  const addMessage = async data => {
    await fetch("https://hoaifood.onrender.com/chatrooms/addMessage", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  };
  return (
    <div className={classes.liveChat}>
      <div className={classes.topChat}>
        <p>Customer Support</p>
        
      </div>
      <div className={classes.chat}>
        <ul className={classes.messages}>
          {message &&
            message.map((value, index) => (
              <li
                key={index}
                className={value.is_admin ? classes.left : classes.right}
              >
                <p>{value.message}</p>
              </li>
            ))}
        </ul>
      </div>
      <div className={classes.underChat}>
        <img src={admin} alt="" />
        <input
          type="text"
          placeholder="Enter Message!"
          onChange={onChangeText}
          value={textMessage}
        />
        
        <FontAwesomeIcon
          icon={faPaperPlane}
          className="mx-2 text-primary"
          onClick={sendMessage}
        />
      </div>
    </div>
  );
};

export default Livechat;
