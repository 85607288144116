import React, { useState, useEffect, useCallback } from 'react';
import Product from './Product';
import Popup from './Popup';
import { popupAction } from '../store/index';
import { useDispatch } from 'react-redux';
import classes from './ProductList.module.css';

const ProductList = () => {
  const [dataProduct, setDataProduct] = useState([]);
  const dispatch = useDispatch();
  // khi click vào 1 vật phẩm truyền dispatch về thông tin vật phẩm đó cho payload
  const showPopupHandler = productData => {
    dispatch(popupAction.showPopup(productData));
  };
  // gọi api cho danh sách sản phẩm
  const fetchProductApi = async () => {
    try {
      const response = await fetch(
        'https://hoaifood.onrender.com/products/hot',
      );

      if (!response.ok) {
        throw new Error('Đã xảy ra lỗi!');
      }

      const data = await response.json();
      setDataProduct(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProductApi();
  }, [fetchProductApi]);

  return (
    <div className={classes.trending}>
      <h5>SẢN PHẨM BÁN CHẠY</h5>
      <div className={classes.products}>
        {dataProduct.length > 0 ? (
          dataProduct.map(item => (
            <Product
              key={item._id}
              id={item._id}
              short_desc={item.short_desc}
              long_desc={item.long_desc}
              category={item.category}
              name={item.name}
              price={item.price}
              img={item.img1}
              sendItem={() => showPopupHandler(item)}
            />
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Popup />
    </div>
  );
};

export default ProductList;
