import { Link } from 'react-router-dom';
import classes from './Categories.module.css';
import mam from '../../image/mam.jpeg';
import nemcha from '../../image/nemcha.jpeg';
import charam from '../../image/charam.jpeg';
import banh from '../../image/banh.webp';
import comchay from '../../image/comchay.jpeg';
const Categories = () => {
  return (
    <div className={classes.categories}>
      <h5>ĐẶC SẢN BÌNH ĐỊNH</h5>
      <div>
        <div className={classes.categoriesImg1}>
          <Link to="/shop">
            <img src={mam} alt="Đặc sản Bình Định, Mắm Nhĩ, Mắm cá cơm" />
          </Link>
          <Link to="/shop">
            <img src={nemcha} alt="Nem chua, Chả bò" />
          </Link>
        </div>
        <div className={classes.categoriesImg2}>
          <Link to="/shop">
            <img src={charam} alt="Chả Ram tôm đất," />
          </Link>
          <Link to="/shop">
            <img src={banh} alt="Bánh Hồng," />
          </Link>
          <Link to="/shop">
            <img src={comchay} alt="Cơm cháy" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Categories;
