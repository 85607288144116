import { useState } from "react";
import { Helmet } from "react-helmet";
import banner from "../image/banner4.png";
import { Link, useNavigate } from "react-router-dom";
import classes from "./RegisterPage.module.css";

const RegisterPage = () => {
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [passInput, setPassInput] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [nameTouched, setNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passTouched, setPassTouched] = useState(false);
  const [phoneTouched, setPhoneTouched] = useState(false);

  const nameIsValid = nameInput.trim() !== "";
  const emailIsValid = emailInput.includes("@");
  const passIsValid = passInput.length >= 5;
  const phoneIsValid = phoneInput.trim() !== "" && phoneInput > 0;
  const nameValueIsValid = !nameIsValid && nameTouched;
  let emailValueIsValid = !emailIsValid && emailTouched;
  const passValueIsValid = !passIsValid && passTouched;
  const phoneValueIsValid = !phoneIsValid && phoneTouched;

  // xử lý validate cho tát cả input đăng ký tài khoản
  const inputNameHandler = event => {
    setMessageErr("");
    setNameInput(event.target.value);
  };
  const nameInputBlurHandler = event => {
    setMessageErr("");

    setNameTouched(true);
  };
  const inputPassHandler = event => {
    setMessageErr("");

    setPassInput(event.target.value);
  };
  const passInputBlurHandler = event => {
    setMessageErr("");

    setPassTouched(true);
  };
  const inputPhoneHandler = event => {
    setMessageErr("");

    setPhoneInput(event.target.value);
  };
  const phoneInputBlurHandler = event => {
    setMessageErr("");

    setPhoneTouched(true);
  };
  const emailInputBlurHandler = event => {
    setMessageErr("");

    setEmailTouched(true);
  };
  const inputEmailHandler = event => {
    setMessageErr("");

    setEmailInput(event.target.value);
  };

  const navigate = useNavigate();

  const registerUser = async user => {
    try {
      const response = await fetch("https://hoaifood.onrender.com/signup", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const formSubmitHandler = async event => {
    event.preventDefault();
    try {
      const response = await registerUser({
        name: nameInput,
        email: emailInput,
        password: passInput,
        phone: phoneInput,
      });
      if (response.status === 201) {
        navigate("/login");
      } else {
        const error = await response.json();
        setMessageErr(error.errors[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={classes.mainSignup}
      style={{
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className={classes.signupForm}>
        <form action="" onSubmit={formSubmitHandler}>
          <h4 className="text-center mb-4 text-secondary">SIGN UP</h4>
          {messageErr && <p className={classes.textErr}>{messageErr}</p>}
          <input
            type="text"
            placeholder="Full Name"
            onChange={inputNameHandler}
            onBlur={nameInputBlurHandler}
            value={nameInput}
            className={nameValueIsValid && classes.invalid}
          />

          <input
            type="text"
            placeholder="Email"
            onChange={inputEmailHandler}
            onBlur={emailInputBlurHandler}
            value={emailInput}
            className={emailValueIsValid && classes.invalid}
          />
          <input
            type="password"
            placeholder="Password"
            onChange={inputPassHandler}
            onBlur={passInputBlurHandler}
            value={passInput}
            className={passValueIsValid && classes.invalid}
          />
          <input
            type="text"
            placeholder="Phone"
            onChange={inputPhoneHandler}
            onBlur={phoneInputBlurHandler}
            value={phoneInput}
            className={phoneValueIsValid && classes.invalid}
          />
          <button type="submit">SIGN UP</button>
          <p className="mt-2 text-secondary text-center">
            Login? <Link to="/login">Click</Link>
          </p>
        </form>
      </div>
    </div>
  );
};
export default RegisterPage;
