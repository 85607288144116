import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../store";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import classes from "./CartItem.module.css";

const CartItem = props => {
  // lấy userData từ redux bằng useSelector
  const userData = useSelector(state => state.login);
  const dispatch = useDispatch();
  // lấy data từng vật phẩm bằng props
  const data = props.item;
  // useState để quản lý số lượng chọn vật phẩm
  const [quantity, setQuantity] = useState(data.value);
  // lấy danh sách người dùng từ local sau đó xác định người dùng nào đang đăng nhập
  const cart = JSON.parse(localStorage.getItem("cart")) || [];

  let indexItem = cart.findIndex(el => el.id === props.item.id);

  function decreaseQuantity() {
    const newQuantity = data.value > 1 ? data.value - 1 : 1;
    setQuantity(newQuantity);
    updateCart(data.id, newQuantity);
  }

  function increaseQuantity() {
    if (quantity < data.count) {
      const newQuantity = data.value + 1;
      setQuantity(newQuantity);
      updateCart(data.id, newQuantity);
    } else {
      alert("Không còn sản phẩm để thêm");
    }
  }

  function updateCart(productId, newQuantity) {
    dispatch(loginAction.updateCart({ productId, newQuantity }));
    cart[indexItem].value = newQuantity;
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  function deleCartHandler() {
    // nút xoá vật phẩm
    dispatch(loginAction.deleCart(indexItem));
    cart.splice(indexItem, 1);
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  return (
    <div className={classes.cartItem}>
      <img src={data.img1} alt={data.category} />
      <h6>{data.name}</h6>
      <p className="text-center my-0">
        {/* xử lý dấu chấm đơn vị */}
        {Number(data.price).toLocaleString()} VND
      </p>
      <div className={classes.quantity}>
        <button className="bg-white text-dark pe-1" onClick={decreaseQuantity}>
          ▼
        </button>
        <input
          value={quantity}
          min="1"
          max="10"
          className="text-center"
          readOnly
        />
        <button className="bg-white text-dark ps-1" onClick={increaseQuantity}>
          ▲
        </button>
      </div>
      <p className="text-center my-0">
        {Number(
          data.price * (data.value > 0 ? data.value : 1)
        ).toLocaleString()}{" "}
        VND
      </p>
      <FontAwesomeIcon icon={faTrashCan} onClick={deleCartHandler} />
    </div>
  );
};

export default CartItem;
