import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { loginAction } from "../store";
import { useNavigate } from "react-router-dom";
import classes from "./DetailProduct.module.css";

const Detail = props => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const userData = useSelector(state => state.login);
  const dispatch = useDispatch();
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  // xử lý nút giảm số lượng
  function decreaseQuantity() {
    setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  }

  // xử lý nút tăng số lượng nếu vượt hàng tồn kho thì không tăng được nữa
  function increaseQuantity() {
    if (quantity < props.item.count) {
      setQuantity(prevQuantity => prevQuantity + 1);
    }
  }
  let userId = sessionStorage.getItem("userId");
  function addCartHandler() {
    let random = Math.random();
    userId = userId ? userId : new Object(random);
    sessionStorage.setItem("userId", userId);
    if (props.item.count === 0) {
      alert("Đã hết hàng");
      return;
    }
    // tạo 1 object để lấy các dữ liệu cần thiết cho 1 vật phẩm chọn mua
    const item = {
      id: props.item._id,
      img1: props.item.img1,
      name: props.item.name,
      price: props.item.price,
      value: quantity,
      count: props.item.count,
      donvi: props.item.type,
    };

    // xác định vật phẩm chọn mua

    let indexItem = cart.findIndex(el => el.id === item.id);

    // khi vật phẩm đó chưa có trong cart thì add vào, không thì cập nhật số lượng cho nó
    if (indexItem === -1) {
      dispatch(loginAction.addCart(item));
      cart.push(item);
    } else {
      const newQuantity = quantity + cart[indexItem].value;
      dispatch(loginAction.updateCart({ productId: item.id, newQuantity }));
      cart[indexItem].value = newQuantity;
    }

    localStorage.setItem("cart", JSON.stringify(cart));
    setQuantity(1);
    navigate("/cart");
  }

  return (
    <div className={classes.detail}>
      <div>
        <img src={props.item.img1} alt={props.item.name} className="mb-2" />
        <img src={props.item.img2} alt={props.item.name} className="mb-2" />
        <img src={props.item.img3} alt={props.item.name} className="mb-2" />
        <img src={props.item.img4} alt={props.item.name} className="mb-2" />
      </div>
      <img src={props.item.img1} alt={props.item.name} />
      <div className={classes.context}>
        <h2>{props.item.name}</h2>
        <p className={classes.price}>
          {Number(props.item.price).toLocaleString()} VND
        </p>
        <p>{props.item.short_desc}</p>
        <h6>Đơn Vị:</h6>
        <p className={classes.category}>{props.item.type}</p>
        {props.item.count > 0 ? <p></p> : <p>Sold out</p>}
        <div className={classes.quantity}>
          <p>QUANTITY</p>
          <button
            className="bg-white text-dark pe-1"
            onClick={decreaseQuantity}
          >
            ▼
          </button>
          <input
            value={quantity}
            min="1"
            max="10"
            className="text-center"
            readOnly
          />
          <button
            className="bg-white text-dark ps-1"
            onClick={increaseQuantity}
          >
            ▲
          </button>

          <button type="button" onClick={addCartHandler}>
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default Detail;
