import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className={classes.container}>
      <div className={classes.footer}>
        <div>
          <h5>CUSTOMER SERVICES</h5>
          <Link to="/">Help & ContLinkct Us</Link>
          <Link to="/">Returns & Refunds</Link>
          <Link to="/">Online Store</Link>
          <Link to="/">Terms & Conditions</Link>
        </div>
        <div>
          <h5>COMPANY</h5>
          <Link to="/">What We Do</Link>
          <Link to="/">Available Services</Link>
          <Link to="/">Lastest Post</Link>
          <Link to="/">FAQs</Link>
        </div>
        <div>
          <h5>SOCIAL MEDIA</h5>
          <Link to="/">Twister</Link>
          <Link to="/">Instagram</Link>
          <Link to="/">Facebook</Link>
          <Link to="/">Pinterest</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
