import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { popupAction } from "../store/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import classes from "./Popup.module.css";

const Popup = () => {
  // xử lý redux cho bật tắt popup
  const popupData = useSelector(state => state.popup);
  const dispatch = useDispatch();
  const closePopupHandler = () => {
    dispatch(popupAction.hidePopup());
  };

  if (!popupData || !popupData.isOpen) {
    return null;
  }
  const data = popupData.productData;
  return (
    <div className={classes.popupContainer}>
      <div onClick={closePopupHandler} className={classes.outBlur}></div>
      <div className={classes.popup}>
        <img src={data.img1} alt={data.name} />

        <div className={classes.imformation}>
          <p onClick={closePopupHandler} className={classes.close}>
            x
          </p>
          <h6>{data.name}</h6>
          <p>{Number(data.price).toLocaleString()}</p>
          <p className={classes.short}>{data.short_desc}</p>
          <Link
            to={`/detail/${data._id}-${data.category}`}
            type="button"
            className="btn-dark btn mt-3"
          >
            <FontAwesomeIcon icon={faShoppingCart} /> View detail
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Popup;
