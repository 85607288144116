import CartList from "./cart/cartList";
import CartTotal from "./cart/CartTotal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./CartPage.module.css";

const CartPage = () => {
  return (
    <div className={classes.cart}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="row bg-light p-5 d-flex justify-content-between">
        <h2>CART</h2>
        <p className={classes.cartLight}>CART</p>
      </div>
      <h4 className="my-5">SHOPPING CART</h4>
      <div className="row">
        <div className="col-12 col-lg-8">
          <CartList />
        </div>
        <div className="col-12 col-lg-4">
          <CartTotal />
        </div>
      </div>
      <div className="col-12 bg-light d-flex justify-content-between p-3">
        <Link to="/shop" className={classes.toShop}>
          <FontAwesomeIcon icon={faArrowCircleLeft} />
          Mua Thêm
        </Link>
        <Link to="/check-out" className={classes.toCheck}>
          Đặt Hàng
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </Link>
      </div>
    </div>
  );
};
export default CartPage;
