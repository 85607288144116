import { useState, useCallback, useEffect } from "react";
import Product from "./Product";
import Categories from "./Categories";
import classes from "./ProductList.module.css";

const Products = () => {
  // tạo ra 2 phần dữ liệu lấy từ api để dể lọc
  const [dataProduct, setDataProduct] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  const [search, setSearch] = useState("");
  const fetchProductApi = useCallback(async () => {
    try {
      const response = await fetch(
        "https://hoaifood.onrender.com/admin/products"
      );

      if (!response.ok) {
        throw new Error("Đã xảy ra lỗi!");
      }

      const data = await response.json();
      setDataProduct(data);
      setAllProduct(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchProductApi();
  }, [fetchProductApi]);
  // hàm filter lọc các sản phẩm theo yêu cầu
  const filterHandler = useCallback(
    category => {
      category === "all"
        ? setDataProduct(allProduct)
        : setDataProduct(allProduct.filter(item => item.category === category));
    },
    [allProduct]
  );
  useEffect(() => {
    // Lọc sản phẩm dựa trên tìm kiếm
    const filteredData = allProduct.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );
    setDataProduct(filteredData);
  }, [search]);

  return (
    <>
      <div className={classes.shopCon}>
        <div className={classes.categoryCon}>
          <Categories onCategoryChange={filterHandler} />
        </div>
        <div className={classes.listCon}>
          <div className="mt-4 pt-3">
            <div className={classes.search}>
              <input
                type="text"
                placeholder="Enter search here!"
                className="p-2"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <div className={classes.product}>
              {dataProduct.length > 0 ? (
                dataProduct.map(item => (
                  <Product
                    key={item.name}
                    id={item._id}
                    short_desc={item.short_desc}
                    long_desc={item.long_desc}
                    category={item.category}
                    name={item.name}
                    price={item.price}
                    img={item.img1}
                  />
                ))
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
