import { Link } from "react-router-dom";
import classes from "./HistoryItem.module.css";

const HistoryItem = props => {
  return (
    <div className={classes.historyItem}>
      <p>{props.item._id}</p>
      <p>{props.item.totalPrice}</p>
      <p>{props.item.status}</p>
      <Link to={`/order/${props.item._id}`}>View</Link>
    </div>
  );
};

export default HistoryItem;
