import { useSelector } from "react-redux";
import CartItem from "./cartItem";
import classes from "./CartList.module.css";

const CartList = () => {
  // danh sách các vật phẩm trong giỏ hàng
  const userData = useSelector(state => state.login);
  const data = JSON.parse(localStorage.getItem("cart")) || [];

  return (
    <>
      <div className={classes.title}>
        <h6>Image</h6>
        <h6>Tên</h6>
        <h6>Giá</h6>
        <h6>Số Lượng</h6>
        <h6>Tổng</h6>
        <h6>Xoá</h6>
      </div>
      {data.map(item => (
        <CartItem item={item} key={item.id} />
      ))}
    </>
  );
};

export default CartList;
