import Products from "./shopComponent/Products";
import { Helmet } from "react-helmet";
import classes from "./ShopPage.module.css";

const ShopPage = () => {
  return (
    <div className={classes.shopPage}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9448406330324983"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <div className="row bg-light p-5 d-flex justify-content-between">
        <h2>Shop</h2>
        <p className={classes.shopLight}>Shop</p>
      </div>
      <Products />
    </div>
  );
};
export default ShopPage;
